import BosonComponent from '../component';
import Site from '../../site';

class MonitorsPage extends BosonComponent {

	public name: string = 'site/monitors';

	/**@var the setInterval for the monitor data */
	public int: ReturnType<typeof setInterval>; // weird return type because node/proper js do different stuff and this is clearer anyway

	/**@var the shared data between the class and the vue component */
	public data = {
		numberOfDay: 1,
		monitors: [],
		counts: {}
	};

	/**
	 * build the component from this context
	 */
	public constructor() {
		super();
		this.initComponent();
	}

	/**
	 * Gets the first set of monitor data and start the interval
	 */
	public async init(): Promise<void> {
		const self = this;

		console.log('- monitors page init');

		self.getMonitorData();

		self.int = setInterval( function() { self.getMonitorData(); }, 30000);
	}

	/**
	 * Gets monitor data from the server and formats the calculated values for the template
	 */
	private async getMonitorData() {
		const self = this;

		const monitors = await Site.API('potato/get', { getCrowFiles: 1, noCache: 1 }, true);
		self.data.counts = {};
		for (const monitor of monitors) {
			self.data.counts[monitor.type] = (self.data.counts[monitor.type] || 0) + 1;
			monitor.hoursSinceMatch = Math.round((Date.now() - new Date(monitor.lastMatch).getTime()) / (1000 * 60 * 60));
			monitor.stateText = (monitor.state != 'running' ? monitor.state : '✓') + (monitor.lastMatch ? ' - last ' + monitor.hoursSinceMatch + 'h' : '-');

			monitor.showHd = monitor.type == 'radio' && monitor.station && monitor.station.radioUseHD;
			// pic
			if (monitor.status === 'released' || Date.now() - new Date(monitor.lastPing).getTime() > 5 * 60 * 1000) {
				monitor.state = 'offline';
				monitor.picHtml = '<div class="offline">' + (monitor.status == 'released' ? 'Empty' : 'Offline <a target="_blank" href="/api/potato/restartServer?id=' + monitor._id + '"><i class="fa fa-refresh"></i></a> <a target="_blank" href="/api/potato/create?id=' + monitor._id + '"><i class="fa fa-times"></i></a>') + '<br><span>' + (monitor.status != 'released' && monitor.lastPing ? monitor.lastPing.split('T')[0] : monitor.instanceType || '') + '</span></div>';
			} else {
				monitor.picHtml = '<a href="https://s3.amazonaws.com/sourceaudio-sad/screens/uatu' + monitor._id + '.png?' + Date.now() + '" target="_blank"><img src="https://s3.amazonaws.com/sourceaudio-sad/screens/uatu' + monitor._id + '.png?' + Date.now() + '"/></a>';
			}


		}
		self.data.monitors = monitors;
	}

	public showMonitor(monitor:any) {
		console.log(JSON.parse(JSON.stringify( monitor )));
	}
}

export default MonitorsPage;