import BosonComponent from '../component';
import Site from '../../site';
import moment from 'moment';

export default class DashboardPage extends BosonComponent {

	public name = 'site/dashboard';

	/**@var the setInterval for the monitor data */
	public int: ReturnType<typeof setInterval>; // weird return type because node/proper js do different stuff and this is clearer anyway

	/**@var the shared data between the class and the vue component */
	public data: any = {
		recentMatches: [],
		displayMatches: [],
		charts: {
			matchesMonth: {
				series: [],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						stacked: true,
						toolbar: {
							show: false
						},
						zoom: {
							enabled: false
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								position: 'bottom',
								offsetX: -10,
								offsetY: 0
							}
						}
					}],
					plotOptions: {
						bar: {
							borderRadius: 8,
							horizontal: false,
						},
					},
					dataLabels: {
						enabled: false
					},
					grid: {
						row: {
							colors: ['#fff', '#f2f2f2']
						}
					},
					xaxis: {
						type: 'datetime',
						labels: {
							rotate: -45
						},
						categories: [],
						tickPlacement: 'on'
					},
					yaxis: {
						title: {
							text: 'Matches'
						}
					},
					legend: {
						position: 'right',
						offsetY: 40
					},
					fill: {
						opacity: 1
					}
				}
			},
			processors: {
				series: [],
				options: {
					chart: {
						height: 400,
						type: 'radialBar'
					},
					plotOptions: {
						radialBar: {
							offsetY: 0,
							startAngle: 0,
							endAngle: 270,
							hollow: {
								margin: 5,
								size: '30%',
								background: 'transparent',
								image: undefined,
							},
							dataLabels: {
								name: {
									show: false,
								},
								value: {
									show: false,
								}
							}
						}
					},
					stroke: {
						lineCap: 'round'
					},
					colors: ['#1ab7ea', '#0084ff', '#39539E'],
					labels: ['Processed', 'Queued', 'Recordings'],
					legend: {
						show: true,
						floating: true,
						fontFamily: 'Lato',
						fontSize: '12px',
						position: 'left',
						offsetX: -10,
						offsetY: 10,
						labels: {
							useSeriesColors: true,
						},
						markers: {
							size: 0
						},
						/*formatter: function (seriesName: string, opts: any) {
							return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
						},*/
						itemMargin: {
							vertical: 3
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								show: false
							}
						}
					}]
				}
			},
			matchesDay: {
				series: [],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						stacked: true,
						toolbar: {
							show: false
						},
						zoom: {
							enabled: false
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								position: 'bottom',
								offsetX: -10,
								offsetY: 0
							}
						}
					}],
					plotOptions: {
						bar: {
							borderRadius: 8,
							horizontal: false,
						},
					},
					dataLabels: {
						enabled: false
					},
					grid: {
						row: {
							colors: ['#fff', '#f2f2f2']
						}
					},
					xaxis: {
						type: 'text',
						labels: {
							rotate: 0,
							formatter: (v: string) => {
								return parseInt(v?.substr(8));
							}
						},
						categories: [],
						tickPlacement: 'on'
					},
					yaxis: {
						title: {
							text: 'Matches'
						}
					},
					fill: {
						opacity: 1
					},
					legend: {
						show: false
					}
				}
			},
			matchesLocations: [{
				name: "",
				stationCount: 0,
				series: [],
				options: {
					chart: {
						type: 'bar',
						height: 350,
						stacked: true,
						toolbar: {
							show: false
						},
						zoom: {
							enabled: false
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								position: 'bottom',
								offsetX: -10,
								offsetY: 0
							}
						}
					}],
					plotOptions: {
						bar: {
							borderRadius: 8,
							horizontal: false,
						},
					},
					dataLabels: {
						enabled: false
					},
					grid: {
						row: {
							colors: ['#fff', '#f2f2f2']
						}
					},
					xaxis: {
						type: 'text',
						labels: {
							rotate: 0,
							formatter: (v: string):string|number => {
								return parseInt(v?.substr(8));
							}
						},
						categories: [],
						tickPlacement: 'on',
						title: {
							text: 'Date'
						}
					},
					yaxis: {
						title: {
							text: 'Matches'
						}
					},
					fill: {
						opacity: 1
					},
					legend: {
						show: false
					}
				}
			}]
		},
		upcomingCities: [
			{
				name: "Atlanta, GA",
				stations: 8
			},
			{
				name: "New York City, NY",
				stations: 16
			},
			{
				name: "Chicago, IL",
				stations: 16
			},
			{
				name: "Philadelphia, PA",
				stations: 16
			},
			{
				name: "Dallas, TX",
				stations: 12
			},
			{
				name: "Washington DC",
				stations: 13
			},
			{
				name: "Houston, TX",
				stations: 11
			},
			{
				name: "San Francisco, CA",
				stations: 16
			},
			{
				name: "Boston, MA",
				stations: 16
			},
		]
	};

	/**@var the attached charts, available for cleanup */
	public charts: any[] = [];

	/**
	 * build the component from this context
	 */
	public constructor() {
		super();
		this.initComponent();
	}

	/**
	 * Gets the first set of monitor data and start the interval
	 */
	public async init(): Promise<void> {
		const self = this;

		console.log('- dashboard page init');

		self.getMatchStats();
		self.getProcessorStats();
		self.getCityMatches();
		self.getRecentMatches();

		self.int = setInterval(function() { self.liveTick(); }, 500);
	}

	/**
	 * Gets the stats on recent matches
	 */
	public async getMatchStats(): Promise<void> {
		const self = this;

		const stats = await Site.API('match/stats');

		// month
		const monthCats = stats.days.map((s: any) => { return s._id; });
		const monthTV = stats.days.map((s: any) => { return s.tv; });
		const monthRadio = stats.days.map((s: any) => { return s.radio; });

		// throw away first date, since it's partial
		monthCats.shift();
		monthTV.shift();
		monthRadio.shift();

		self.data.charts.matchesMonth.options.xaxis.categories = monthCats;
		self.data.charts.matchesMonth.series = [
			{
				name: 'TV',
				data: monthTV
			},
			{
				name: 'Radio',
				data: monthRadio
			}
		];

		// day
		stats.hours = stats.hours.sort((a: any, b: any) => { return parseInt(a._id) - parseInt(b._id); });
		//console.log('- sorted:', stats.hours);
		const dayCats = stats.hours.map((s: any) => { return s._id; });
		const dayTV = stats.hours.map((s: any) => { return s.tv; });
		const dayRadio = stats.hours.map((s: any) => { return s.radio; });

		// throw away first date, since it's partial
		dayCats.shift();
		dayTV.shift();
		dayRadio.shift();

		self.data.charts.matchesDay.options.xaxis.categories = dayCats;
		self.data.charts.matchesDay.series = [
			{
				name: 'TV',
				data: dayTV
			},
			{
				name: 'Radio',
				data: dayRadio
			}
		];
	}

	/**
	 * Gets match data per city
	 */
	public async getCityMatches(): Promise<void> {

		// we'll use this to create deep copies of the city chart config, one per city
		const cityBase = JSON.stringify(this.data.charts.matchesLocations[0]);
		// reset the list of chart configs
		this.data.charts.matchesLocations = [];

		// get the list of active cities and their matches from the server
		const allCityStats = await Site.API('match/cityMatches');

		for (const cityName in allCityStats) {
			if (!allCityStats.hasOwnProperty(cityName)) continue;
			const cityStats = allCityStats[cityName];

			// create a new chart data object for this city
			const cityChart = JSON.parse(cityBase);
			cityChart.name = cityName;

			// throw away first date, since it's partial
			cityStats.dates.shift();
			cityStats.matches.shift();

			// add the graph data
			cityChart.options.xaxis.title.text = new Intl.NumberFormat().format(cityStats.matches.reduce((accumulator, current) => accumulator + current)) + ' matches total (30 days)';
			cityChart.options.xaxis.categories = cityStats.dates;
			cityChart.series = [
				{
					name: 'Radio',
					data: cityStats.matches
				}
			];
			cityChart.stationCount = cityStats.stations.length;
			this.data.charts.matchesLocations.push(cityChart);

			// remove the city from upcoming
			for (let i = 0, len = this.data.upcomingCities.length; i < len; i++) {
				if (this.data.upcomingCities[i].name === cityName) {
					this.data.upcomingCities.splice(i, 1);
					break;
				}
			}
		}

	}

	/**
	 * Gets the stats about the detect processors (crows)
	 */
	public async getProcessorStats(): Promise<any> {
		const self = this;

		const stats = await Site.API('crow/status', { statsOnly: 1 });

		self.data.charts.processors.options.labels = ['Processed: ' + stats.processed, 'Queued: ' + stats.queued, 'Last 24h: ' + stats.added];
		self.data.charts.processors.series = [100 * stats.processed / stats.added, 100 * stats.queued / stats.added, 100];

		return stats;
	}

	/**
	 * Gets a recent log of matches and the tracks associated
	 */
	public async getRecentMatches(): Promise<any> {
		const self = this;

		const stats = await Site.API('match/recent');

		// format the data
		const matches:any[] = [];
		for (const m of stats.matches) {
			let track = stats.tracks[ m.mark ];
			if (!track) {
				track = {
					title: 'Deleted track',
					catalog: { name: 'Deleted' },
					artist: 'Track history lookup <a href="https://music.sourceaudio.com/admin/lookup.php?watermark=' + m.mark + '&searchType=track"<i class="fad fa-external-link"></i>',
					art: 'https://music.sourceaudio.com/images/shared/default_editor6.jpg'
				}
			} else {
				m.title = track.title;
				m.art = 'https://music.sourceaudio.com/' + (track.album?.image || '/images/shared/default_album.jpg');
			}
			m.dateText = moment(m.date).format('YYYY-MM-DD h:mm:ss a');
			m.track = track;

			// clip https://s3.amazonaws.com/sourceaudio-sad/2021-5-6/8698452-1620260248126-8.mp4
			m.clipType = m.video.includes('undefined') ? 'audio' : 'video';
			m.clipIcon = m.clipType === 'audio' ? 'fa-radio' : 'fa-tv-music';
			m.clipUrl = 'https://s3.amazonaws.com/sourceaudio-sad/' + (m.clipType === 'audio' ? m.audio : m.video);

			matches.push(m);
		}

		return self.data.recentMatches = self.data.recentMatches.concat(matches);
	}

	/**
	 * Checks the time minus 1hr and presents the data as a "live" feed
	 */
	public async liveTick():Promise<void> {
		const self = this;

		const now = Date.now() - (60 * 60000);

		// get newest current match
		const newest = self.data.displayMatches[ 0 ]?.date || 0;
		//console.log('- newest match:', newest, now, self.data.displayMatches.length);

		for (const m of self.data.recentMatches) {
			if (m.date > newest && m.date <= now) {
				//console.log('- adding new match', m.dateText);
				self.data.displayMatches.push(m);
			}
		}

		self.data.displayMatches.sort((a, b) => { return b.date - a.date; });

	}
}