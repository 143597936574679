import BosonComponent from '../component';
import Site from '../../site';

class TestPage extends BosonComponent {

	public name: string = 'site/test';

	/**@var the shared data between the class and the vue component */
	public data = {
		numberOfDay: 1
	};

	/**
	 * build the component from this context
	 */
	public constructor() {
		super();
		this.initComponent();
	}

	public async init(): Promise<void> {
		const self = this;

		console.log('- test page init');

		return;
	}

	public async testFunc() {
		const self = this;
		console.log('- running a test function');

		self.data.numberOfDay = 1 + Math.round( 100 * Math.random());
	}

}

export default TestPage;