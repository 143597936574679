import $j from 'jquery';
import Site from './site';
import 'regenerator-runtime/runtime'

(window as any).app = Site;

$j(function () {

	Site.init();

});