import BosonComponent from '../component';
import Site from '../../site';
// @ts-ignore
import pages from './*.component.ts';

class Main extends BosonComponent {

	public name: string = 'site/main';

	/**@var the shared data between the class and the vue component */
	public data = {
		message: 'ice cream sandwich',
		page: 'dashboard',
		logged: false,
		avatar: null
	};

	/**
	 * build the component from this context
	 */
	public constructor() {
		super();
		this.initComponent();
	}

	public async init(): Promise<void> {
		const self = this;

		console.log('- main init');

		console.log('- debug pages:', pages);

		// debug
		//await Site.sleep(1000);

		// sa auth
		await Site.auth();
		if (Site.user?.admin) {
			self.data.logged = true;
			self.data.avatar = Site.user.avatar;
		} else {
			console.log('- redirecting');
			window.location.href = 'https://music.sourceaudio.com/login.php?forward=' + encodeURIComponent(window.location.href);
		}

		return;
	}

	/**
	 * Gets the page component and mounts it to the main app
	 * @param path - the string representing the path to the component, starts in `/components/site/`
	 */
	public async setPage( path : string): Promise<void> {
		const self = this;

		try {
			const p = pages[ path ];

			if (!p || !p.default) throw new Error('No such page: ' + path);

			// remove current page if exists
			if (Site.page) await Site.page.destroy();

			const page: BosonComponent = new p.default();
			page.mount( '#page-content' );
			page.init();
			Site.page = page;
			self.data.page = page.name;
		} catch(e) {
			console.log('- error loading page:', e);
		}

	}


}

export default Main;